import React, { useContext, useEffect, useState } from 'react'
import axios  from 'axios';
import { Link } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Loding from '../Loding/Loding.jsx';
import Slider from 'react-slick';

import { useParams } from 'react-router-dom';


export default function TvSeriesDetails(){

  
  const [proudctDetails, setproudctDetails] = useState(null);
    let params = useParams();
    
  async function getproudctdetails(id) { 
  
  let {data}= await axios.get(`${process.env.REACT_APP_API_URL}?password=${process.env.REACT_APP_API_PASSWORD}&username=${process.env.REACT_APP_API_USERNAME}&action=get_series&category_id=${id}`)
  
  setproudctDetails (data);
  
  
  
  
  }
  
  useEffect  (()=>{
  getproudctdetails(params.id);
  },[])
  
   
    return <>
   
   <div className="container-fluid">
  <div className="row">
    {proudctDetails?.map((movie)=>{
      return <div key={Math.random()} className="col-md-3 p-3 card border-5">
        <Link to={`/TvSeriesinfo/${movie?.series_id}`} >
        <img src={movie.cover} alt="..." />
        <div className="card-body">
          <h5 className="card-title">{movie.name}</h5>
          <h6 className='card-footer'>{        movie.series_id          }</h6>
        </div>
        </Link>

      </div>
    })}
  </div>
</div>
    </>
}