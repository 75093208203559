import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Create a separate CSS file for custom styles

export default function Navbar() {
  return (
    <>
      <nav className="navbar d-flex navbar-expand-lg bg-body-tertiary container-fluid p-3 m-auto bg-light shadow-sm">
        <div className="container-fluid" >
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse  " id="navbarSupportedContent">
            {/* Left side navigation links */}
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Movies">
                  Movies
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/TvSeries">
                  TvSeries
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/LiveTv">
                  LiveTv
                </Link>

              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/AllMovies">
                  AllMovies
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/UserData">
                  UserData
                </Link>
              </li>
            </ul>

            {/* Right side social media icons */}
            <ul className="navbar-nav ms-auto">
              <li className="nav-item d-flex align-items-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https:Fb.com/7atem.a7med"
                  className="nav-icon"
                >
                  <i className="fa-brands fa-facebook-f fa-lg"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/hatemabdelnoor/"
                  className="nav-icon"
                >
                  <i className="fa-brands fa-instagram fa-lg"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/HatemAbdelnoor"
                  className="nav-icon"
                >
                  <i className="fa-brands fa-github fa-lg"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/hatem-abdelnoor-28ab04156/"
                  className="nav-icon"
                >
                  <i className="fa-brands fa-linkedin fa-lg"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
