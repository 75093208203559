import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import YoutubeEmbed from "../../JS/YoutubeEmbed";
import Slider from "react-slick";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TvSeriesInfo() {
    const [productDetails, setProductDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    let params = useParams();

    // Fetch product details from API
    async function getProductDetails(id) {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}?password=${process.env.REACT_APP_API_PASSWORD}&username=${process.env.REACT_APP_API_USERNAME}&action=get_series_info&series_id=${id}`);
            setProductDetails(data);
            setIsLoading(false);  // Stop loading when data is fetched
            
            
        } catch (err) {
            console.error(err);
            setError('Failed to fetch data');
            setIsLoading(false);  // Stop loading even if there is an error
        }
    }

    useEffect(() => {
        getProductDetails(params.id);
    }, [params.id]);

    const movieLink = `${process.env.REACT_APP_API_vido_URL}/movie/${process.env.REACT_APP_API_USERNAME}/${process.env.REACT_APP_API_PASSWORD}/${params.id}.mkv`;
/*

*/
    if (isLoading) {
        return <div className="d-flex justify-content-center align-items-center min-vh-100">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>;
    }

    if (error) {
        return <div className="alert alert-danger" role="alert">
            {error}
        </div>;
    }

    // Settings for the Slider (Carousel)
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="container py-5">
            <div className="row justify-content-center">
                <div className="col-md-5 mb-4">
                    <div className="card shadow-sm">
                        <img className="card-img-top" src={productDetails?.info?.cover} alt={productDetails?.info?.name} />
                        <div className="card-body">
                            <h5 className="card-title">{productDetails?.info?.name}</h5>
                            <p className="card-text">{productDetails?.info?.plot}</p>
                        </div>
                    </div>
                </div>
                     <div className="row col-md-5 w-100">
                    <h3 className="mt-4 ">YouTube Trailer</h3>
                    <YoutubeEmbed  embedId={productDetails?.youtube_trailer} />
                    </div>  
                </div>

            {/* Loop through each season, exclude season 0, and create a carousel for its episodes */}
            {productDetails?.seasons?.map((season, i) => (
                    <div key={season.id} className="my-5">
                        <h4 className="mb-3">Season {i + 1}</h4>  {/* Display current season number */}

                        {/* For each season, display its episodes in a carousel */}
                        <Slider {...sliderSettings}>
                            {productDetails?.episodes[i+1]?.map((episode) => ( 
                                <div key={episode.id} className="p-3">
                                    <Link to={`/EpisodeInfo/${episode.id}`} className="card shadow-sm h-100">
                                        <img src={episode.cover || productDetails?.info?.cover} alt={episode.title} className="card-img-top" />
                                        <div className="card-body">
                                            <h6 className="card-title">{episode.title}</h6>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </Slider>
                    </div>
                ))}
        </div>
    );
}

// Type checking for props
TvSeriesInfo.propTypes = {
    productDetails: PropTypes.object,
};
