import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

export const UserData = () => {

    // Function to format UNIX timestamp to readable date
    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString();
    };

    return (
        <div className="container py-5">
            <div className="row">
                {/* User Info Card */}
                <div className="col-md-6 mb-4">
                    <div className="card shadow-sm">
                        <div className="card-header bg-primary text-white">
                            <h5>User Information</h5>
                        </div>
                        <div className="card-body">
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <th>Username</th>
                                        <td>{data[0].user_info.username}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>{data[0].user_info.status}</td>
                                    </tr>
                                    <tr>
                                        <th>Auth</th>
                                        <td>{data[0].user_info.auth === 1 ? 'Authenticated' : 'Not Authenticated'}</td>
                                    </tr>
                                    <tr>
                                        <th>Expiration Date</th>
                                        <td>{formatDate(data[0].user_info.exp_date)}</td>
                                    </tr>
                                    <tr>
                                        <th>Created At</th>
                                        <td>{formatDate(data[0].user_info.created_at)}</td>
                                    </tr>
                                    <tr>
                                        <th>Max Connections</th>
                                        <td>{data[0].user_info.max_connections}</td>
                                    </tr>
                                    <tr>
                                        <th>Active Connections</th>
                                        <td>{data[0].user_info.active_cons}</td>
                                    </tr>
                                    <tr>
                                        <th>Allowed Output Formats</th>
                                        <td>{data[0].user_info.allowed_output_formats.join(', ')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* Server Info Card */}
                <div className="col-md-6 mb-4">
                    <div className="card shadow-sm">
                        <div className="card-header bg-info text-white">
                            <h5>Server Information</h5>
                        </div>
                        <div className="card-body">
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <th>Server URL</th>
                                        <td>{data[0].server_info.url}</td>
                                    </tr>
                                    <tr>
                                        <th>Port</th>
                                        <td>{data[0].server_info.port}</td>
                                    </tr>
                                    <tr>
                                        <th>HTTPS Port</th>
                                        <td>{data[0].server_info.https_port}</td>
                                    </tr>
                                    <tr>
                                        <th>RTMP Port</th>
                                        <td>{data[0].server_info.rtmp_port}</td>
                                    </tr>
                                    <tr>
                                        <th>Protocol</th>
                                        <td>{data[0].server_info.server_protocol}</td>
                                    </tr>
                                    <tr>
                                        <th>Timezone</th>
                                        <td>{data[0].server_info.timezone}</td>
                                    </tr>
                                    <tr>
                                        <th>Current Time</th>
                                        <td>{data[0].server_info.time_now}</td>
                                    </tr>
                                    <tr>
                                        <th>Process Running</th>
                                        <td>{data[0].server_info.process ? 'Yes' : 'No'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Example usage with provided data
const data = [
    {
        "user_info": {
            "username": "skl85",
            "password": "53770547",
            "message": "<p><br></p>",
            "auth": 1,
            "status": "Active",
            "exp_date": "1734867607",
            "is_trial": "0",
            "active_cons": "1",
            "created_at": "1703245207",
            "max_connections": "1",
            "allowed_output_formats": ["m3u8", "ts"]
        },
        "server_info": {
            "url": "mhiptv.com",
            "port": "2095",
            "https_port": "25463",
            "server_protocol": "http",
            "rtmp_port": "25462",
            "timezone": "Africa/Cairo",
            "timestamp_now": 1726000129,
            "time_now": "2024-09-10 22:28:49",
            "process": true
        }
    }
];

