import React, { useContext, useEffect, useState } from 'react'
import axios  from 'axios';
import { Link } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Slider from 'react-slick';
import PropTypes from "prop-types";
import YoutubeEmbed from "../../JS/YoutubeEmbed";

import { useParams } from 'react-router-dom';


export default function EpisodeInfo () {



    const [proudctDetails, setproudctDetails] = useState(null);
    let params = useParams();
    
  async function getproudctdetails(id) { 
  
  let {data}= await axios.get(`${process.env.REACT_APP_API_URL}?password=${process.env.REACT_APP_API_PASSWORD}&username=${process.env.REACT_APP_API_USERNAME}&action=get_series_info&series_id=${id}`)
  
  const allData= data
  setproudctDetails (allData);
  
  
  }
  
  useEffect  (()=>{
  getproudctdetails(params.id);
  },[])
  
  const moiveLink= `${process.env.REACT_APP_API_vido_URL}/series/${process.env.REACT_APP_API_USERNAME}/${process.env.REACT_APP_API_PASSWORD}/${params.id}.mkv`
const id = params.id+"mkv"



  return  <>
   
   <div className="container d-flex  ">


      <div className="row mt-3">

      <video
            src={moiveLink} 
            width='1440'
            height='680'
            controls
            
            muted
            playsInline
        />

     <button className='btn btn-btn-outline-success'> <a className='fa' href={moiveLink}   f target='_blank' rel='noopener noreferrer'  > download </a></button>  
    


  </div>


  </div>
    </>
};
