import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom"
import Loding from '../Loding/Loding';
import { useQuery } from "react-query";
export default function LiveTv(){
function getLiveTv(){

    return axios.get(`${process.env.REACT_APP_API_URL}?password=${process.env.REACT_APP_API_PASSWORD}&username=${process.env.REACT_APP_API_USERNAME}&action=get_live_categories`)
   .then((response) =>response).catch((err) =>err)
}
    async function setLiveTv(){
            let response=await getLiveTv();
            setLiveTvDitails(response)
            
            
    };
    
    let { isLoading, data, isError } = useQuery("LiveTv", getLiveTv);

    const [LiveTvDitails, setLiveTvDitails] = useState(null);
    return<>    {data !== null ? (
        <div className="container my-4">
          <div className="row d-flex justify-content-center">
            {data?.data?.map((MoviesDitails, index) => (
              <div key={index} className="col-md-4 col-lg-3 mb-4">
                <Link to={`/LiveTvDetailes/${MoviesDitails.category_id}`} className="text-decoration-none">
                  <Card className="h-100 shadow-sm border-0">
                    <Card.Header className="bg-primary text-white text-center">
                      {MoviesDitails?.category_name}
                    </Card.Header>
                    <Card.Body className="d-flex justify-content-center align-items-center">
                      <Button variant="info" className="mt-2">View Details</Button>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <Loding />
        </div>
      )}
</>
}