import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player'

// Render a YouTube video player
 
function Home (){
  const [movies, setMovies]= useState([])
/*
async function getData(){  
  let {data} = await axios.get(`${process.env.REACT_APP_API_URL}?password=${process.env.REACT_APP_API_PASSWORD}&username=${process.env.REACT_APP_API_USERNAME}&action=get_vod_streams`)
    let {data} = await axios.get(`  ${process.env.REACT_APP_API_URL}?password=${process.env.REACT_APP_API_PASSWORD}&username=${process.env.REACT_APP_API_USERNAME}&action=get_live_streams`)
                              
  setMovies(data)
  
  
}
useEffect(()=>{
  getData()

},[])
*/



return<>

      <div className="container-fluid d-flex justify-content-center align-items-center  mt-5">
        <div className="row col-md-10 flex-wrap justify-content-center align-content-center">

          <div className="col-md-3 p-3 m-2 card text-center">
            <a href="Movies" className="text-decoration-none">
              <h1 className="fa m-auto">Movies</h1>
            </a>
          </div>

          <div className="col-md-3 p-3 m-2 card text-center bg-info text-white">
            <a href="TvSeries" className="text-decoration-none text-white">
              <h1 className="fa m-auto">Tv Series</h1>
            </a>
          </div>

          <div className="col-md-3 p-3 m-2 card text-center bg-danger text-white">
            <a href="LiveTv" className="text-decoration-none text-white">
              <h1 className="fa m-auto">Live Tv</h1>
            </a>
          </div>

        </div>
      </div>
    </>
  
}

export default Home;
