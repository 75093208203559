import React, { useContext, useEffect, useState } from 'react'
import axios  from 'axios';
import { Link } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Slider from 'react-slick';
import PropTypes from "prop-types";
import "./LiveTvDetailes .style.css";
import YoutubeEmbed from "../../JS/YoutubeEmbed";
import { Card, CardImg } from "react-bootstrap";
import Loding from '../Loding/Loding';

import { useParams } from 'react-router-dom';


export default function LiveTvDetailes () {



    const [proudctDetails, setproudctDetails] = useState(null);
    let params = useParams();
    
  async function getproudctdetails(id) { 
  
  let {data}= await axios.get(`${process.env.REACT_APP_API_URL}?password=${process.env.REACT_APP_API_PASSWORD}&username=${process.env.REACT_APP_API_USERNAME}&action=get_live_streams&category_id=${id}`)
  
  const allData= data
  setproudctDetails (allData);
  
  
  }
  
  useEffect  (()=>{
  getproudctdetails(params.id);
  },[])
  
  const moiveLink= `${process.env.REACT_APP_API_vido_URL}/live/${process.env.REACT_APP_API_USERNAME}/${process.env.REACT_APP_API_PASSWORD}/${params.id}.mkv`
const id = params.id+"mkv"


  return  <>
   
{  proudctDetails !== null  ? <div className="containar col-md-12 d-flex justify-content-center flex-wrap  p-2">
    {proudctDetails?.map((proudctDetails, index) => (
        <div key={index} className="row col-md-3 card p-3">
         <Link to={`/LiveTvDetailes/${proudctDetails.id}`} >
         <h2 className=' card-header'>{proudctDetails.name}</h2>
         <img className='card-img' src={proudctDetails.stream_icon} alt="" />


       </Link>

       </div>
    ))}
    </div>: <Loding/>
}
    </>
};
