import React from "react";
import  "./Footer.css"

export default function Footer(){












    return<> <div className="  "> 
<div className="container-fluid  d-flex flex-wrap  mt-5 p-3 justify-content-center align-items-center footer " > 
     <div className="row">
                       
                         <p> <span className="fw-bold"> Email:</span> hatem6616@gmail.com</p>
                         <p> <span className="fw-bold"> Phone:</span> (+2)01552549558 </p>

                         </div>
<div className="row">
<p>© Copyright All Rights Reserved</p>
                  <p> Designed by hatemAbdelnoor</p></div>    
                  </div></div>
    </>
    
} 