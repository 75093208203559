import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Loding from '../Loding/Loding';
import Pagination from 'react-bootstrap/Pagination';
import { useQuery } from 'react-query';
import './AllMovies.css'; // Custom styling

export default function AllMovies() {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const moviesPerPage = 20;

    function getMovies() {
        return axios.get(`${process.env.REACT_APP_API_URL}?password=${process.env.REACT_APP_API_PASSWORD}&username=${process.env.REACT_APP_API_USERNAME}&action=get_vod_streams`)
            .then((response) => response)
            .catch((err) => err);
    }

    let { isLoading, data, isError } = useQuery("AllMovies", getMovies);

    // Ensure data is available and is an array
    const movies = Array.isArray(data?.data) ? data.data : [];

    // Filter movies based on search query
    const filteredMovies = movies.filter((movie) =>
        movie.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Calculate the index range for the current page
    const indexOfLastMovie = currentPage * moviesPerPage;
    const indexOfFirstMovie = indexOfLastMovie - moviesPerPage;
    const currentMovies = filteredMovies.slice(indexOfFirstMovie, indexOfFirstMovie + moviesPerPage);

    // Total number of pages
    const totalPages = Math.ceil(filteredMovies.length / moviesPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <div className="container-fluid">
                {/* Search Input */}
                <div className="row justify-content-center my-4">
                    <div className="col-md-6">
                        <input
                            type="text"
                            className="form-control search-bar"
                            placeholder="Search for a movie by name..."
                            value={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                                setCurrentPage(1); // Reset to the first page when searching
                            }}
                        />
                    </div>
                </div>

                {isLoading ? (
                    <div className="text-center my-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : filteredMovies.length > 0 ? (
                    <>
                        <div className="container-fluid col-md-12 d-flex justify-content-center flex-wrap p-2">
                            <div className="row">
                                {currentMovies.map((movie) => (
                                    <div key={movie.stream_id} className="col-md-3 p-3">
                                        <div className="card movie-card border-5 shadow-sm">
                                            <Link to={`/MoviesDetailes/${movie?.stream_id}`} className="text-decoration-none">
                                                <img src={movie.stream_icon} alt={movie.name} className="card-img-top movie-img" />
                                                <div className="card-body">
                                                    <h5 className="card-title">{movie.name}</h5>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Pagination */}
                        <Pagination className="justify-content-center mt-4">
                            <Pagination.Prev
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            />
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            <Pagination.Next
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            />
                        </Pagination>
                    </>
                ) : (
                    <div className="text-center mt-4">
                        <h5>No movies found</h5>
                    </div>
                )}
            </div>
        </>
    );
}
