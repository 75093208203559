import React from "react";
import "./Loding.css";

export default function Loding(){
    return(
<>
<div class="looder container-fluid d-flex justify-content-center align-items-center vh-100  center 100 ">
<div class="🤚">
	<div class="👉"></div>
	<div class="👉"></div>
	<div class="👉"></div>
	<div class="👉"></div>
	<div class="🌴"></div>		
	<div class="👍"></div>
</div></div>
      </>    )
}