import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Loding from '../Loding/Loding.jsx';
import './CategoryDetails.module.css'

export default function CategoryDetails() {
  const [productDetails, setProductDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State to store the search term

  let params = useParams();

  // Fetch product details based on category ID
  async function getProductDetails(id) {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}?password=${process.env.REACT_APP_API_PASSWORD}&username=${process.env.REACT_APP_API_USERNAME}&action=get_vod_streams&category_id=${id}`);
    setProductDetails(data);
    
  }

  // Handle search input change
  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase()); // Set the search term to lowercase for case-insensitive search
  };

  // Filter the movies based on search term
  const filteredMovies = productDetails?.filter(movie =>
    movie.name.toLowerCase().includes(searchTerm)
  );

  // Call API when component mounts
  useEffect(() => {
    getProductDetails(params.id);
  }, [params.id]);

  // Loading state
  if (!productDetails) {
    return <Loding />;
  }

  return (
    <div className="container-fluid">
      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search movies by name..."
          value={searchTerm}
          onChange={handleSearch} // Update search term on input change
        />
      </div>

      <div className="row">
        {filteredMovies?.map((movie) => ( // Use filteredMovies instead of productDetails
          <div key={movie.stream_id} className="col-md-3 p-3">
            <Link to={`/MoviesDetailes/${movie?.stream_id}`} className="text-decoration-none">
              <div className="card border-5 shadow">
                <img src={movie.stream_icon} alt={movie.name} className="img-fluid" />
                <div className="card-body">
                  <h5 className="card-title">{movie.name}</h5>
                  <h6 className="card-footer">{movie.category_id}</h6>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
