import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import YoutubeEmbed from "../../JS/YoutubeEmbed";
import "./MoviesDetailes.style.css";

export default function MoviesDetailes() {
  const [productDetails, setProductDetails] = useState(null);
  let params = useParams();

  async function getProductDetails(id) {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}?password=${process.env.REACT_APP_API_PASSWORD}&username=${process.env.REACT_APP_API_USERNAME}&action=get_vod_info&vod_id=${id}`);
    const allData = data?.info;
    setProductDetails(allData);
    
  }

  useEffect(() => {
    getProductDetails(params.id);
  }, [params.id]);

  const movieLink = `${process.env.REACT_APP_API_vido_URL}/movie/${process.env.REACT_APP_API_USERNAME}/${process.env.REACT_APP_API_PASSWORD}/${params.id}.mkv`;


  return (
    <div className="container my-5">
      <div className="row d-flex justify-content-between">
        {/* Movie Image and Details */}
        <div className="col-md-5">
          <div className="card shadow-lg">
            <img
              className="card-img-top"
              src={productDetails?.movie_image}
              alt={productDetails?.name || "Movie Image"}
            />
            <div className="card-body">
              <h5 className="card-title text-center">
                {productDetails?.name}
              </h5>
              <p className="card-text">
                {productDetails?.description}
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <span className="badge bg-primary">
                  Category ID: {productDetails?.category_id}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Movie Video and Trailer */}
        <div className="col-md-6">
          <div className="mb-4">
            <video
              src={movieLink}
              className="w-100 shadow-sm"
              height="400"
              controls
              muted
              playsInline
            />
          </div>

          <div className="text-center mb-4">
            <a
              className="btn btn-outline-success"
              href={movieLink}
              download={productDetails?.name}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Movie
            </a>
          </div>

          <h3 className="text-center mb-3">YouTube Trailer</h3>
          <div className="d-flex justify-content-center">
            <YoutubeEmbed embedId={productDetails?.youtube_trailer} />
          </div>
        </div>
      </div>
    </div>
  );
}
